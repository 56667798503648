* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* .App {
  height: 100%;
} */

html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Overpass', sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: transparent;
  position: fixed;
  width: 100%;
  z-index: 999;
}

header ul {
  list-style-type: none;
  font-weight: bold;
}

header img {
  height: 75px;
  width: 75px;
}

a {
  color: black;
  text-decoration: none;
}

#about {
  display: flex;
  height: 100vh;
}

#about div {
  width: 50%;
  flex-grow: 1;
  padding: 250px;
}

#bio {
  display: flex;
  height: 100%;
  align-items: center;
}

h1 {
  /* color: red; */
  font-weight: 400;
  font-size: 60pt;
  letter-spacing: -1pt;
  text-align: center;
}

.bioBold {
  color: red;
  font-weight: 900;
}

#headshot {
  background-image: url("../public/assets/images/greg4.jpg");
  background-size: cover;
  background-position: center;
}

#work {
  /* padding: 50px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

.sample {
  flex: 1 0 700px;
  background-size: cover;
  background-position: center;
  height: 50vh;
}

.sample button {
  display: inline;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 3px;
  border: 1px solid white;
  border-radius: 50px;
  background-color: transparent;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: 'Overpass', sans-serif;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
}

.overlay {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  transition: 1s ease-in-out;
}

/* #sample1 {
  background-image: url("../images/rodemap.png");
}

#sample2 {
  background-image: url("../images/codefront.jpg");  
}

#sample3 {
  background-image: url("../images/codequiz.png");
} */

.overlay:hover {
  opacity: 0.9;
}

.project h2 {
  font-size: 32px;
  font-weight: bold;
}

.project p {
  margin: 15px;
  padding: 0px;
  font-size: 12pt;
}

#contact {
  display: flex;
  justify-content: space-between;
  padding: 50px;
}

#contact img {
  padding: 0;
  width: auto;
  height: 24px;
  margin-right: 15px;
}

#socials img {
  margin-right: 0;
  margin-left: 15px;
}

#contact p {
  display: inline;
  padding: 0;
  margin-right: 75px;
  font-size: 28px;
  text-align: center;
}

#address div {
  display: inline;
}

footer {
  width: 100;
  padding: 100px;
  text-align: center;
}

h5 {
  letter-spacing: 0.5pt;
  font-weight: 100;
  font-size: 12px;
}

@media screen and (max-width: 1920px) {
  #about div {
      padding: 175px;
  }
      
  #bio h1 {
      font-size: 48pt;
  }
}

@media screen and (max-width: 1536px) {
  #about div {
      padding: 100px;
  }

  #bio h1 {
      font-size: 42px;
  }

  /* #work {
      padding: 0;
      padding-top: 50px;
      padding-bottom: 50px;
  } */

  .sample {
      width: 50vw;
      height: 33.3vh;
      flex: 1 0 513px;
  }
}

@media screen and (max-width: 1366px) {
  header {
      position: relative;
  }

  #about {
      flex-direction: column;
  }

  #about div {
      width: 100%;
      padding: 50px;
  }

  #headshot {
      background-size: cover;
      background-position: top;
      height: 100%;
  }

  #bio {
      height: auto;
  }

  .sample {
    width: 50vw;
    height: 33.3vh;
    /* flex: 1 0 900px; */
}

  #contact {
      flex-direction: column;
      align-items: center;
      align-content: center;
  }

  #contact img {
      height: 30px;
  }

  #contact p {
      margin: 0;
      margin-left: 10px;
      font-size: 24px;
  }

  #address div {
      margin: 0 auto;
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
  }

  #address img {
      display: none;
      visibility: hidden;
      z-index: -1;
  }

  #socials img {
      margin: 15px;
  }
}

@media screen and (max-width: 1024px) {
  #bio h1 {
      font-size: 36px;
  }

  /* .sample {
      flex: 1 0 828px;
  } */
}

@media screen and (max-width: 828px) {
  header, #about, #contact {
      flex: 1 0 400px;
  }

  .sample {
      height: 50vh;
      width: 100vw;
      flex: 1 0 415px;
  }
}
